<template>
  <div
    v-if="article"
    class="v-container"
    itemscope
    itemtype="https://schema.org/Article"
  >
    <meta
      :content="article.Title"
      itemprop="headline"
    />
    <meta
      v-if="!stringIsNullOrWhitespace(article.MetaKeywords)"
      :content="article.MetaKeywords"
      itemprop="keywords"
    />
    <meta
      :content="article.CoverImage.Domain + article.CoverImage.Path"
      itemprop="image"
    />
    <div class="v-article v-mb-xl">
      <div
        v-if="stringIsNullOrWhitespace(article.ContentFirstPart)"
        class="v-mb-sm"
        v-html="sanitize(article.ContentSecondPart)"
      />
      <div
        v-else
        class="v-mb-sm"
        v-html="sanitize(article.ContentFirstPart)"
      />
      <arora-swiper-slider
        pagination-dynamic-bullets
        :items="articleProducts"
        :space-between="20"
        max-items="auto"
      >
        <template #item="articleItem: ProductInList">
          <menu-product-in-list-item-card-slider :product="articleItem" />
        </template>
      </arora-swiper-slider>
      <div
        v-if="!stringIsNullOrWhitespace(article.ContentFirstPart)"
        class="v-mb-sm"
        v-html="sanitize(article.ContentSecondPart)"
      />
    </div>
  </div>
  <span
    v-else
    class="v-error-color"
    >{{ route.meta.ID }}</span
  >
</template>

<script setup lang="ts">
import type { ProductInList } from '~types/menuStore'
import type { Article } from '~types/preloadRequests'

import { type GUID, useCommon } from '@arora/common'

const route = useRoute()

const appConfig = useAppConfig()
const { stringIsNullOrWhitespace } = useCommon()
const { sanitize } = useI18nSanitized()

const article = ref<Article | undefined>()

const { makeDefaultOption } = useProduct()
const articleProducts = ref<ProductInList[]>([])

onBeforeMount(async () => {
  article.value = appConfig.SEOPreloadData.Articles.find((a) => a.ID === (route.meta.ID as GUID))

  if (!article.value) return

  articleProducts.value = appConfig.Products.filter(
    (product) => product.GroupID === article.value!.LinkedGroup
  )

  for (const menuItem of articleProducts.value ?? []) {
    if (menuItem) makeDefaultOption(menuItem)
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-article {
  .swiper-slide {
    width: fit-content;
  }

  .swiper-pagination-bullets {
    .swiper-pagination-bullet-active--non-dynamic {
      background: variables.$SecondaryBackgroundColor;
    }
  }
}
</style>
